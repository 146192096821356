.main-container{
    background: #f5f5dc;
    width: 100%;
}

.contact-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.2em;
    text-decoration: none;
    color: white;
    background-color: #3498db; /* Adjust the color as needed */
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .contact-button:hover {
    background-color: #2980b9; /* Adjust the hover color as needed */
  }

  .contact-button-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 35px;
  }