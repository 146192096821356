/* AboutMe.css */

.about-me-container {
    background-color: #f5f5dc; /* Cream color */
    color: #660f0b; /* Dark text color */
    padding: 20px;
    text-align: center;
    background: #f5f5dc;
  }
  
  .header {
    margin-bottom: 20px;
  }
  
  .header h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .header p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .photo {
    max-width: 300px;
    height: auto;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .content {
    text-align: left;
  }
  
  .content h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .content p {
    font-size: 1.2em;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .footer {
    margin-top: 20px;
  }
  
  /* Additional styles can be added as needed */