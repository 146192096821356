/* HomePage.css */

/* Global styles */
body {
    margin: 0;
    font-family: 'Jost', sans-serif;
  }
  
  /* Homepage container */
  .homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f5f5dc;
    width: 100%;
  }
  
  /* Header section styles */
  .header-section {
    text-align: center;
    padding: 20px;
    background-color: #f5f5dc; /* Cream color */
  }
  
  .logo {
    width: 200px; /* Adjust the size as needed */
    height: auto;
  }
  
  h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #660f0b;
  }
  
  p {
    font-size: 1.2em;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #660f0b;
  }
  
  /* Image section styles */
  .image-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5dc; /* Cream color */
  }
  
  .image {
    max-width: 37%;
    height: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .book-now-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.2em;
    text-decoration: none;
    color: white;
    background-color: #3498db; /* Adjust the color as needed */
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .book-now-button:hover {
    background-color: #2980b9; /* Adjust the hover color as needed */
  }
  
  /* Cream-colored background section styles */
  .cream-background {
    background-color: #f5f5dc; /* Cream color */
    padding: 40px;
    text-align: center;
    color: #333; /* Dark text color */
  }
  
  /* Another Image section styles */
  .image-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5dc; /* Cream color */
  }
  
  /* Sliding Component section styles */
  .sliding-component-section {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    /* padding: 20px; */
    background-color: #f5f5dc; /* Cream color */
  }
  
  /* Additional styles can be added as needed */
  

  .review-slider-container {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
  }
  
  /* Review slider styles */
  .review-slider {
    /* display: flex;
    overflow: hidden; */
    width: 70%; 
    /* margin: 20px; */
  }
  
  .review {
    /* min-width: 100%;
    box-sizing: border-box;
    transition: transform 0.5s ease; */
  }
  
  .review.active {
    transform: translateX(-20px);
  }
  
  .review-text {
    font-size: 1.2em;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .review-author {
    font-size: 1em;
    text-align: right;
  }
  
  /* Navigation styles */
  .navigation {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-top: 10px;
  }
  
  button {
    font-size: 1.5em;
    padding: 5px 10px;
    cursor: pointer;
  }

  .slick-slide:not(.slick-active) {
    visibility: hidden;
  }

  .slick-active{
    visibility: visible;
  }