/* CommentCarousel.css */

.comment-carousel-container {
    width: 100%;
    margin: auto;
    max-width: 600px; /* Adjust as needed for your design */
    margin-bottom: 40px;
  }
  
  .comment-slide {
    text-align: center;
    width: 100%;
    /* padding: 20px; */
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #2db4d2;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   /* margin: 0 10px;  Add margin for better separation between slides on small screens */
  }
  


  .slick-prev:before,
.slick-next:before {
  content: ''; /* Remove the content (text) from the arrows */
}
  
  .slick-dots {
    bottom: -20px; /* Adjust the position of dots for better visibility */
  }
  
  .slick-dots li button:before {
    font-size: 10px;
    color: #999;
  }
  
  .slick-dots li.slick-active button:before {
    color: #333;
  }
  