.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px; /* Optional: Add padding as needed */
  }
  
  .social-icons {
    display: flex;
    gap: 10px;
  }
  
  .contact-info {
    flex-grow: 1; /* Takes remaining space */
    text-align: center;
    margin-top: 10px; /* Optional: Add margin as needed */
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .flag {
    margin-left: 20px; /* Adjust margin as needed */
  }
  
  .flag-photo {
    height: 100px; /* Adjust height as needed */
    width: auto;
  }
  
  /* Responsive styling for smaller screens */
  @media (max-width: 600px) {
    .container {
      flex-direction: column; /* Stack items in a column on smaller screens */
      align-items: stretch;
    }
  
    .social-icons {
      justify-content: center;
    }
  
    .contact-info {
      margin-top: 0; /* Reset margin */
    }
  
    .flag {
      margin-left: 0; /* Reset margin */
      margin-top: 10px; /* Adjust margin as needed */
    }
  }